import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20230809-Rate-Sheet.pdf";
const date = "09.08.2023";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>

    <br />
    <p>With effect from Wednesday 09 August 2023 we will be making the following changes to our range:</p>
    <p><strong>Summary of changes</strong></p>
    <p>We have reintroduced our cashback incentive across a selection of UK Residential First Time Buyer and Home Mover rates.</p>
    <p><strong>UK Residential First Time Buyer Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75% and 80% LTV have decreased</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have decreased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
      <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
      <li>5 Year Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
    </ul>
    <p><strong>UK Residential Home Mover Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75% and 80% LTV have decreased</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have decreased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
      <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
      <li>5 Year Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
    </ul>
    <p><strong>UK Residential Remortgage Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
      <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have decreased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
      <li>5 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
      <li>5 Year Premier Exclusive at 60%, 70% and 75% LTV have decreased</li>
    </ul>
    <p><strong>UK Residential Remortgage Cash Back Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
      <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
      <li>5 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
    </ul>
    <p><strong>International Residential Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
      <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
      <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
      <li>5 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
      <li>5 Year Premier Exclusive at 60%, 70% and 75% LTV have decreased</li>
    </ul>
    <p>There are no changes to any other interest rates at this time.</p>
    <p>An updated mortgage rate sheet will be published on Wednesday 09 August 2023 to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
